import { Inject, Injectable } from '@angular/core';
import { RoleModel, RoleRequestModel } from '../_models/role.model';
import { HttpMethodsService } from './http-methods.services';
import { map } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import saveAs from 'file-saver';
import { UrlService } from './url.services';
import { HttpUtilsService } from './http-utils.service';


@Injectable({
    providedIn: 'root',
})
export class SalesSumaryService {
    constructor(
        private http: HttpClient,
        private _httpService: HttpMethodsService,
        private httpUtils: HttpUtilsService,
        private routing: UrlService
    ) { }

    find(filters: {}) {
        return this._httpService.get(`sales_sumary`, {}, filters);
    }
    //sales_sumary_export
    export(filters: {}, file_name: string) {
        let httpHeaders = this.httpUtils.getHTTPHeaders();
        const httpParams = this.httpUtils.getFindHTTPParams({ filter: filters });
        var route = this.routing.getUrl(`sales_sumary_export`);
        return this.http.get(`${route}`, {
            observe: 'response',
            responseType: 'blob',
            headers: httpHeaders,
            params: httpParams
        }).pipe((map((res) => {
            const blob = res.body || new Blob();
            saveAs(blob, `${file_name}-${Date.now()}.xlsx`)
            return res
        })))
    }


}
